import React from "react";

import ContactForm from "../../../../ContactForm";

const FluessiggasContact = ({ name = "fluessiggas" }) => {
    return (
        <ContactForm
            phoneHeadline="0 42 62 – 79 94 18"
            phoneLink="+494262799418"
            emailHeadline="fluessiggas.online@hoyer.de"
            urlEnding="fuessiggas"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
        />
    );
};

export default FluessiggasContact;
